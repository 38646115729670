<template>
  <VDialog :value="show" max-width="500" class="no-print">
    <VCard class="text-xs-center no-print">
      <VCardTitle class="orange darken-2 white--text">
        <VFlex xs12 mb-2><h2>Confirm Locking Reports</h2></VFlex>
      </VCardTitle>
      <VCardText class="font-weight-medium pa-5">
        <p v-if="!reports.length">No reports to lock!</p>
        <template v-else>
          <p class="px-2"
            >Are you sure you want to Lock the {{ reports.length }} visible
            report{{ reports.length === 1 ? '' : 's' }}?</p
          >
          <transition name="fade">
            <div v-if="locking">
              <p>Locking Reports</p>
              <p
                >Number of Remaining Reports :
                {{ reports.length - numLockedReports }}</p
              >
            </div>
          </transition>
          <BaseButton
            :color="confirmed ? 'red' : 'blue'"
            :loading="locking"
            large
            @click="confirmLock"
            >{{
              confirmed ? 'Are you sure?' : 'Lock Visible Reports'
            }}</BaseButton
          >
        </template>
      </VCardText>
      <VCardActions text-xs-right>
        <VSpacer />
        <BaseButton color="orange" outline small @click="close"
          >Cancel</BaseButton
        >
      </VCardActions>
    </VCard>
  </VDialog>
</template>
<script>
import VDialog from '@vuetify/VDialog'
import { VCardText, VCardActions } from '@vuetify/VCard'
import { mapActions } from 'vuex'
import { HumanFields, ReportStatuses } from '@constants/knack'

export default {
  name: 'LockReportsModal',
  components: {
    VDialog,
    VCardText,
    VCardActions,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    reports: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      confirmed: false,
      timeout: null,
      locking: false,
      numLockedReports: 0,
    }
  },
  computed: {}, // methods()
  watch: {
    confirmed(confirmed) {
      if (confirmed) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => (this.confirmed = false), 2000)
      }
    },
  },
  methods: {
    ...mapActions(['batchUpdateReports']),
    close() {
      this.$emit('close')
    },

    increaseLockedReports(res) {
      this.numLockedReports++
    }, // increaseLockedReports

    async confirmLock() {
      if (!this.confirmed) {
        this.confirmed = true
        return false
      }
      this.locking = true
      await this.lockReports()
      this.locking = false
      this.close()
    }, // confirmLock()

    async lockReports() {
      if (!this.reports.length) {
        this.$notify({ type: 'warn', text: 'Nothing to lock!' })
        return false
      }
      const { REPORT_STATUS } = HumanFields.DAILY_REPORT
      let lockedReports = this.reports.map((r) => {
        return { ID: r.ID, [REPORT_STATUS]: ReportStatuses.LOCKED }
      })
      try {
        let refreshedReports = await this.batchUpdateReports({
          reports: lockedReports,
          cb: this.increaseLockedReports,
        })
        this.$emit('doneLocking', refreshedReports)
        return refreshedReports
      } catch (err) {
        throw new Error(err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.vue-dialog {
  color: $gray-body;
  .dialog-content {
    text-align: center;
  }

  .vue-dialog-buttons {
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 0.9rem;
    button {
      text-transform: uppercase;
      transition: background-color, 0.3s ease, color 0.3s ease;
    }
  }
}
</style>
