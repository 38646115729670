<template>
  <WithQuery :base-filters="baseFilters">
    <div slot-scope="{ filters }">
      <ReportsListView
        view-title="Lock Reports"
        :items-per-page="9"
        :filters="filters"
        no-filters
      >
        <template
          slot="header-buttons"
          slot-scope="{ reports, areReportsLoading, refreshPage }"
        >
          <VLayout
            v-if="!areReportsLoading && reports.length"
            row
            wrap
            justify-center
          >
            <BaseButton
              color="orange darken-2"
              md-icon="lock"
              @click="showLockModal = true"
              >Mark as Locked</BaseButton
            >
            <BaseDateRangeControls />
            <LockReportsModal
              v-if="showLockModal"
              :show="showLockModal"
              :reports="reports"
              @close="showLockModal = false"
              @doneLocking="refreshPage"
            />
          </VLayout>
        </template>
      </ReportsListView>
    </div>
  </WithQuery>
</template>

<script>
import * as appConfig from '@src/app.config'
import ReportsListView from '@components/ReportsListView/ReportsListView'
import WithQuery from '@components/DataProviders/WithQuery'
import LockReportsModal from '@modals/LockReportsModal'

import { HumanFields, ReportStatuses } from '@constants/knack'
import { createNamespacedHelpers, mapActions } from 'vuex'

import dayjs from 'dayjs'

const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'LockReportsListView',
  components: { ReportsListView, WithQuery, LockReportsModal },
  metaInfo: {
    title: 'Lock Reports',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {
      showLockModal: false,
      dateRange: {
        startDate: '',
        endDate: '',
      },
    }
  },
  computed: {
    ...routeState(['query']),
    baseFilters() {
      return [
        {
          field: HumanFields.DAILY_REPORT.REPORT_STATUS,
          operator: 'is',
          value: ReportStatuses.COMPLETED,
        },
        {
          field: HumanFields.DAILY_REPORT.REPORT_DATE,
          // there is no "is after or before" option with knack?
          type: 'date',
          operator: 'is after',
          value: {
            all_day: false,
            date: this.dateRange.startDate,
          },
        },
        {
          field: HumanFields.DAILY_REPORT.REPORT_DATE,
          // there is no "is before or during" option with knack?
          type: 'date',
          operator: 'is before',
          value: {
            all_day: false,
            date: this.dateRange.endDate,
          },
        },
      ]
    },
  },
  methods: {
    ...mapActions(['updateReport', 'refreshPage', 'batchUpdateReports']),

    resetAndApplyDateRange() {
      if (this.dateRange.start) {
        let formattedDate = this.getFormattedDateForDayjs(this.dateRange.start)
        this.dateRange.startDate = dayjs(formattedDate).format('MM-DD-YYYY')
      }

      if (this.dateRange.end) {
        let formattedDate = this.getFormattedDateForDayjs(this.dateRange.end)
        this.dateRange.endDate = dayjs(formattedDate).format('MM-DD-YYYY')
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          dateRange: JSON.stringify({
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
          }),
        },
      })
    }, // resetAndApplyDateRange()

    removeDateRange() {
      this.dateRange = { startDate: '', endDate: '' }
      this.resetAndApplyDateRange()
    }, // removeDateRange()

    getFormattedDateForDayjs: function(dateString, splitChar = '/') {
      var dateParts = dateString.split(splitChar)
      var m = dateParts[0]
      var d = dateParts[1]
      var y = dateParts[2]

      var date = [y, m, d].join('-')

      return date
    }, // getFormattedDateForDayjs()
  },
}
</script>

<style lang="scss" scoped>
// TODO (ES) Make Sure we def don't need this here
// @import '@assets/scss/main.scss';

.action-btns-wrap {
  justify-content: center;
  .btn {
    flex-basis: auto;
    flex-grow: 0;
    &:first-child {
      margin-right: 10px;
    }
  }
}
</style>
